import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { REVIEWS_APP_ID, THANK_YOU_PAGE_ID, THANK_YOU_WIDGET_ID } from '~/app-ids';
import { addTPAPage } from '~/editor/page-utils';

export const installTankYouPage = async (editorSDK: FlowEditorSDK) => {
  const pageRef = await addTPAPage(editorSDK, {
    title: 'Thank You Page',
    pageId: THANK_YOU_PAGE_ID,
  });

  await editorSDK.document.tpa.add.component('', {
    componentType: editorSDK.document.tpa.TPAComponentType.Widget,
    appDefinitionId: REVIEWS_APP_ID,
    widget: {
      widgetId: THANK_YOU_WIDGET_ID,
      wixPageId: pageRef.id,
      shouldNavigate: false,
    },
  });
};
