import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { REVIEWS_APP_ID } from '~/app-ids';

export const addTPAPage = async (
  editorSDK: FlowEditorSDK,
  { pageId, title }: { pageId: string; title: string },
) => {
  const appData = await editorSDK.tpa.app.getDataByAppDefId('', REVIEWS_APP_ID);
  return editorSDK.pages.add('', {
    title,
    shouldAddMenuItem: false,
    shouldNavigateToPage: false,
    definition: {
      data: {
        tpaApplicationId: appData.applicationId,
        appDefinitionId: REVIEWS_APP_ID,
        tpaPageId: pageId,
        managingAppDefId: REVIEWS_APP_ID,
        descriptionSEO: '',
        indexable: true,
        isLandingPage: false,
        isMobileLandingPage: false,
        isPopup: false,
        metaData: {
          isHidden: false,
          isPreset: false,
          pageId: 'masterPage',
        },
        metaKeywordsSEO: '',
        pageSecurity: {
          dialogLanguage: '',
          passwordDigest: '',
          requireLogin: false,
        },
        pageTitleSEO: '',
        pageUriSEO: pageId,
        translationData: {
          uriSEOTranslated: false,
        },
        type: 'Page',
        underConstruction: false,
      },
    },
  });
};
